<template>
    <component
        :is="tag"
        :to="tag === 'router-link' && data.relative_url"
        :href="data.link || false"
        :target="data.link && data.target || false"
        class="banner-ratio banner-ratio-main"
        @click="onClick($event, data.relative_url)"
    >
        <base-picture
            :lazy="lazy"
            class="banner-img"
        >
            <base-img
                :lazy="lazy"
                :src="getImageSrc(data.manifest.phone.image)"
                :srcset="getImageSrcset(data.manifest.phone.image)"
                :sizes="getImageSizes()"
                :alt="data.name"
                class="rounded"
            />
        </base-picture>
    </component>
</template>

<script>
import { BREAKPOINTS, BREAKPOINTS_DEVICES } from '../utils/constants';
import { getSrcCF, scrollTo } from '../utils';
import BasePicture from './BasePicture.vue';
import BaseCountdown from './BaseCountdown.vue';

const breakpoints = {
    motoG4: BREAKPOINTS_DEVICES.motoG4,
    ...BREAKPOINTS,
};

export default {
    name: 'Banner',

    components: {
        BasePicture,
        BaseCountdown,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
        lazy: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        tag() {
            let output = 'div';

            if (this.data.link) output = 'a';
            else if (this.data.relative_url) output = 'router-link';

            return output;
        },
    },

    created() {
        this.breakpoints = {
            motoG4: BREAKPOINTS_DEVICES.motoG4,
            ...BREAKPOINTS,
        };
    },

    methods: {
        onClick(event, wantedUrl) {
            const wantedUrlPath = wantedUrl.split('#');

            if (wantedUrlPath.length === 2 && wantedUrlPath[0] === this.$route.path) {
                event.preventDefault();
                scrollTo(wantedUrlPath[1]);
            }
        },

        scrollTo: (anchor) => {
            scrollTo(anchor);
        },

        getImageSrc(src) {
            return getSrcCF(src, '1200x342');
        },

        getImageSrcset(src) {
            return `
                ${getSrcCF(src, '330x94')} 330w,
                ${getSrcCF(src, '784x223')} 784w,
                ${getSrcCF(src, '1200x342')} 1200w
            `;
        },

        getImageSizes() {
            return `
                (max-width: ${breakpoints.motoG4}px) 330px,
                (min-width: ${breakpoints.motoG4 + 1}px) and (max-width: ${breakpoints.md}px) 784px,
                (min-width: ${breakpoints.md + 1}px) 1200px
            `;
        },
    },
};
</script>

<style lang="scss">
@import "./../scss/05-components/banner";
</style>
